import { post } from "./request";

export const postWhatsAppMessage = async (body, headers = {}) => {
  return await post("/admin-message/whatsapp", body, headers);
};

export const postSms = async (body, headers = {}) => {
  return await post("/admin-message/sms", body, headers);
};

export const validateAccessKey = async (body, headers = {}) => {
  return await post("/admin-message/auth", body, headers);
};

import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/global.scss";
import "./App.scss";
import Home from "./components/Home";
import NavbarComponent from "./components/Navbar";
import AdminMessage from "./components/AdminMessage";

import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import authConfig from "./auth.config";
import ECSDesign from "./assets/images/ecs-design.png";

Amplify.configure(authConfig);

function App() {
  const [searchKey, setSearchKey] = useState("");
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);
  return authState === AuthState.SignedIn && user ? (
    <Router>
      <div className="App">
        <NavbarComponent user={user} onSearch={setSearchKey}></NavbarComponent>
        <Routes>
          <Route path="/" element={<Home searchKey={searchKey}></Home>} />
          <Route path="/message" element={<AdminMessage></AdminMessage>} />
        </Routes>
        <div className="ecs-clip">
          <img src={ECSDesign} alt="" className="ecs-logo" />
        </div>
      </div>
    </Router>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn
        headerText="Login to Rocket-CX Labs"
        usernameAlias="email"
        slot="sign-in"
        hideSignUp
      ></AmplifySignIn>
    </AmplifyAuthenticator>
  );
}

export default App;

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import {
  postWhatsAppMessage,
  postSms,
  validateAccessKey,
} from "../utils/api-service";
import { setKeyValue } from "../utils";
import "./AdminMessage.style.scss";

const AdminMessage = (props) => {
  let defaultState = {
    whatsapp: {
      id: "",
      message: `
Hello Jane,
Greeting from RocketCX energy. This is update notification for your upcoming energy provider switch date to RocketCX, scheduled on 25-OCT-2020 at 12:00 pm. We request you to please provide us picture of your meter reading taken on the switch time to help us make this switch successful.
Thank You for your purchase with RocketCX energy.
      `.trim(),
    },
    pwa: {
      phone: "",
      message: `
Hello Jane,
Greeting from RocketCX energy. This is update notification for your  energy provider switch scheduled to happen today at 10:30 am. We request you to please upload your existing meter reading picture taken just before the switch time using our web app for our records. Thank You for your purchase with RocketCX
      `.trim(),
    },
    accessCode: null,
  };
  let accessCodeInput = "";
  const [data, setData] = useState(defaultState);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    variant: "primary",
    message: "No Message",
    show: false,
    timeout: null,
    onModal: false,
  });

  const alertTimeout = () => {
    return setTimeout(() => {
      clearTimeout(alert.timeout);
      setAlert({
        show: false,
      });
    }, 5000);
  };

  const handleFormChange = (event) => {
    console.log(event.target.name);
    let tmpData = JSON.parse(JSON.stringify(data));
    setKeyValue(tmpData, event.target.name, event.target.value);
    setData(tmpData);
  };

  const setAccessCode = async (e) => {
    e && e.preventDefault();
    setLoading(true);
    let tmpData = JSON.parse(JSON.stringify(data));
    let response = await validateAccessKey(data.whatsapp, {
      "x-api-key": accessCodeInput,
    });
    console.log(response);
    let success = /2.*/.test("" + response.status);
    if (success) {
      tmpData.accessCode = accessCodeInput;
      setData(tmpData);
      setAlert({
        show: false,
      });
    } else {
      let tmpAlert = {
        show: true,
        message: response.message || "Invalid access key",
        variant: "danger",
        timeout: alertTimeout(),
        onModal: true,
      };
      clearTimeout(alert.timeout);
      setAlert(tmpAlert);
    }
    setLoading(false);
  };

  const handleWhatsappMessage = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(data.whatsapp);
    let response = await postWhatsAppMessage(data.whatsapp, {
      "x-api-key": data.accessCode,
    });
    console.log(response);
    clearTimeout(alert.timeout);
    let success = /2.*/.test("" + response.status);
    let tmpAlert = {
      show: true,
      message: success
        ? "Message sent successfully"
        : response.message || "Failed to send message",
      variant: success ? "success" : "danger",
      timeout: alertTimeout(),
    };
    setAlert(tmpAlert);
    setLoading(false);
  };

  const handleSms = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(data.pwa);
    let response = await postSms(data.pwa, {
      "x-api-key": data.accessCode,
    });
    console.log(response);
    clearTimeout(alert.timeout);
    let success = /2.*/.test("" + response.status);
    let tmpAlert = {
      show: true,
      message: success
        ? "SMS request placed successfully"
        : response.message || "Failed to send SMS",
      variant: success ? "success" : "danger",
      timeout: alertTimeout(),
    };
    setAlert(tmpAlert);
    setLoading(false);
  };

  return (
    <Container className="container">
      <Alert
        className="notification"
        show={alert.show && !alert.onModal}
        variant={alert.variant}
        onClose={() => {
          clearTimeout(alert.timeout);
          setAlert({ show: false });
        }}
        dismissible
      >
        {alert.message}
      </Alert>
      <Modal show={data.accessCode == null} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>Enter access code</Modal.Title>
        </Modal.Header>
        <Form onSubmit={setAccessCode}>
          <Modal.Body>
            <Form.Group>
              <Alert
                show={alert.show && alert.onModal}
                variant={alert.variant}
                onClose={() => {
                  clearTimeout(alert.timeout);
                  setAlert({ show: false });
                }}
                dismissible
              >
                {alert.message}
              </Alert>
              <Form.Control
                required={true}
                onChange={(e) => {
                  accessCodeInput = e.target.value;
                }}
                type="password"
                name="accessCode"
                placeholder="Access code"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={loading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <div className="header-title">
        <h2>Send admin messages</h2>
      </div>
      <Form className="form-section" onSubmit={handleWhatsappMessage}>
        <Row>
          <Col md={12} lg={10} xl={8}>
            <div className="switch-form">
              <div className="form-title">
                <h5 className="form-title">Pre-Switch</h5>
                <hr />
              </div>
              <Row>
                <Col md={4} className="form-item-name">
                  WhatsApp User Id
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      required={true}
                      onChange={handleFormChange}
                      title="WhatsApp user ID"
                      type="text"
                      name="whatsapp.id"
                      placeholder="a18fa7b549598394e2e10000"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="form-item-name">
                  Message
                </Col>
                <Col md={8}>
                  <Form.Group controlId="messageForm.message">
                    <Form.Control
                      onChange={handleFormChange}
                      name="whatsapp.message"
                      required={true}
                      as="textarea"
                      value={data.whatsapp.message}
                      rows="5"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={8}></Col>
                <Col md={4}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="form-button"
                    disabled={loading}
                  >
                    Send WhatsApp Notification
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>

      <Form className="form-section" onSubmit={handleSms}>
        <Row>
          <Col md={12} lg={10} xl={8}>
            <div className="switch-form">
              <div className="form-title">
                <h5 className="form-title">Switch-Date</h5>
                <hr />
              </div>
              <Row>
                <Col md={4} className="form-item-name">
                  Phone Number (With country-code)
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      required={true}
                      onChange={handleFormChange}
                      type="text"
                      title="Phone number with country code"
                      name="pwa.phone"
                      placeholder="+11234567890"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="form-item-name">
                  Message
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      required={true}
                      name="pwa.message"
                      value={data.pwa.message}
                      onChange={handleFormChange}
                      as="textarea"
                      rows="5"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={8}></Col>
                <Col md={4}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="form-button"
                    disabled={loading}
                  >
                    Send SMS Notification
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AdminMessage;

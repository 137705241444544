/**
 * Set value for nested key in an object
 */
export const setKeyValue = (objRef, nestedKey, value) => {
  let ref = objRef;
  let keys = nestedKey.split(".");
  keys.forEach((key, index) => {
    if (index < keys.length - 1) {
      ref = ref[key];
    } else {
      ref[key] = value;
    }
  });
};

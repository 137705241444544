const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

export const post = async (path, body = {}, headers = {}) => {
  let response;
  try {
    let apiResponse = await fetch(baseUrl + path, {
      method: "post",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json", ...headers },
    });
    let data = await apiResponse.json();
    response = {
      success: true,
      status: apiResponse.status,
      message: data.message || "OK",
      data: data,
    };
  } catch (e) {
    console.log(e);
    response = {
      success: false,
      error: "Request failed",
    };
  }

  return response;
};

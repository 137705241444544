import React from "react";
import { Container, Nav } from "react-bootstrap";
import "./Home.style.scss";
import { ReactComponent as SMSAppIcon } from "../assets/images/sms-24px.svg";
import { ReactComponent as PhoneIcon } from "../assets/images/stay_primary_portrait-24px.svg";
import { ReactComponent as ChartIcon } from "../assets/images/insert_chart-24px.svg";
import { ReactComponent as DesktopIcon } from "../assets/images/desktop_mac-24px.svg";
import { Link } from "react-router-dom";

const Home = (props) => {
  const allApps = [
    {
      name: "Omni-channel Desktop",
      url: "https://deploy-new-format.dalithbf475hm.amplifyapp.com/",
      image: <DesktopIcon className="app-icon" />,
      content:
        "Single pane of glass to contact centre agents, providing contextual information relevant to the interaction with the customer, across multiple channels.",
    },
    {
      name: "Rocket-CX Energy PWA",
      url: "https://deploy-new-format.d1f2usuvgosl8k.amplifyapp.com/",
      image: <PhoneIcon className="app-icon" />,
      content:
        "Demo customer facing application for RocketCX Energy Pvt. Ltd. Customer use this application for product purchase and contact customer support.",
    },
    {
      name: "OCD Admin Message",
      url: "/message",
      image: <SMSAppIcon className="app-icon" />,
      content:
        "Demo Admin panel to send required WhatsApp and text messages to cover part of customer journey.",
    },
    {
      name: "Wallboard",
      url: "https://d199ccep6atjb9.cloudfront.net/",
      image: <ChartIcon className="app-icon" />,
      content:
        "Configurable reporting panel to display real-time calling stats, KPI information, and call center goals against actual.",
    },
  ];

  let searchKey = (props.searchKey || "").toLowerCase();
  const searchResult = allApps.filter((app) => {
    if (props.searchKey.trim().length === 0) {
      return true;
    }
    return app.name.toLowerCase().includes(searchKey);
  });

  return (
    <Container className="container">
      <Nav className="nav nav-tabs">
        <li className="active">
          <Link to="/">
            <button className="tab-button">All Apps</button>
          </Link>
        </li>
      </Nav>
      {searchResult.length === 0 && <div className="no-apps">No app found</div>}
      <div className="cards">
        {searchResult.map((app, index) => {
          return (
            <a
              href={app.url}
              target="_blank"
              rel="noopener noreferrer"
              className="app-link"
            >
              <div className="app-card" title={app.name}>
                <div className="app-background" />
                <div className="app-icon-place">{app.image}</div>
                <div className="app-title">
                  <h5 className="app-name">{app.name}</h5>
                  <p className="app-content">{app.content}</p>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </Container>
  );
};

export default Home;

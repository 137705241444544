import React from "react";
import { SearchOutlined } from "@material-ui/icons";
import "./Navbar.style.scss";
import ecsLogo from "../assets/images/logo-inverse.svg";
import { useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

const signOut = async () => {
  await Auth.signOut();
  window.location.reload();
};

const NavbarComponent = (props) => {
  let location = useLocation();

  return (
    <nav className="navbar">
      <div className="logo" href="/">
        <img className="navbar-logo" src={ecsLogo} alt="ECS"></img>
      </div>
      <div className="search-section">
        {location.pathname === "/" && (
          <div className="search-input-div">
            <input
              className="search-input"
              type="text"
              placeholder="Search for ECS products"
              onChange={(e) => props.onSearch(e.target.value)}
            ></input>
            <SearchOutlined className="search-icon" />
          </div>
        )}
      </div>
      <div style={{ flex: 1 }} />
      {/* <div className="dp-section">
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={
            <Dropdown.Menu show>
              <Dropdown.Item eventKey="1">{userName}</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="1" onClick={signOut}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          }
        >
          <PersonOutlined className="dp" />
        </OverlayTrigger>
      </div> */}
      <div className="user-actions">
        {props.user && (
          <div className="user-details">
            <div className="user-name">Welcome</div>
            <div className="user-email">{props.user.attributes.email}</div>
          </div>
        )}
        <button onClick={signOut} className="logout">
          Logout
        </button>
      </div>
    </nav>
  );
};

export default NavbarComponent;
